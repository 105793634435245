import React, { Component } from 'react'
import { makeStyles } from '@material-ui/styles'
import PianoTerapeutico from './PianoTerapeutico'
import PianiTerapeutici from './PianiTerapeutici'
import Trattamenti from './Trattamenti'
import Fatture from './Fatture'
import Accoglienza from './Accoglienza'
import AppuntamentiPaziente from './AppuntamentiPaziente'
import TaskPaziente from './TaskPaziente'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import ListIcon from '@material-ui/icons/List'
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
//import DialogPaziente from './dialogs/DialogPaziente'
import Fab from '@material-ui/core/Fab' 
import Tooltip from '@material-ui/core/Tooltip'

import { BrowserRouter as Router, Route, Link as RouterLink } from "react-router-dom"
import { Link } from '@material-ui/core'
import { Add, Edit } from '@material-ui/icons'
import Container from '@material-ui/core/Container'
import api from "../api_calls/api"
import DialogModuli from "../dialogs/DialogModuli";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";


const useStyles = makeStyles(theme => ({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    }, 
      palette: {
        primary: {
          light: '#0000',
          main: '#00000',
          dark: '#0000',
          contrastText: '#fff',
        },
        secondary: {
          light: '#ff7961',
          main: '#f44336',
          dark: '#ba000d',
          contrastText: '#000',
        },
      },
}));






function TabContainer(props) {
  return (
    <Typography component="div" style={{ padding: 8 * 3 }}>
      {props.children}
    </Typography>
  );
}

class Pazienti extends Component {
  
  constructor(props) {
    super(props);
    this.state = {
      displayed_form: '',
      logged_in: localStorage.getItem('token') ? true : false,
      user: {},
      value: props.tab,
      paziente: props.paziente,
      curr_piano: null,
      curr_cod_fattura: null,
      pid: props.pid,
      lista_pazienti: [],
      openCDialog: false,
      ricerca_paz: ""
    } 
  }

  toggleConsOpen = () => {
    this.setState({
      openCDialog: !this.state.openCDialog
    })
  };

  updatePianoObj = (piano_obj) => {
    this.setState({
      curr_piano: null,
      piano: null,
      value: 'piano'
    },
    () => this.setState({
      curr_piano: piano_obj.id,
      piano: piano_obj,
      value: 'piano'
    }));
  }
  
  componentDidMount() {
    if (this.state.logged_in) {
      console.log("Pazienti didmount...")
/*      fetch(`${process.env.REACT_APP_API_URL}/dc/current_user/`, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(res => res.json())
        .then(json => {
          this.setState({username: json.username})
        }) */

      if (this.props.pid !== undefined && this.props.pid !== null) {
        const url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_paziente/${this.props.pid}`
        fetch(url_paziente, {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        })
          .then(result => result.json())
          .then(result => {
            console.log("DATI PAZIENTE: " + JSON.stringify(result))
            try {
              const paziente = result.results[0]
              this.setState({
                paziente: paziente,
                value: 'piano'
              })
            } catch (e) {
              this.setState({
                logged_in: false
              })
            }
          })
      }
    }

    /*if (this.props.pid === undefined || this.props.pid === null) {
      this.cercaPazienti({target: {value: ''}})
    }*/

  }

  componentDidUpdate (old) {
    if (old.paziente !== this.props.paziente) {
      this.setState({curr_piano: null, piano: null, paziente: this.props.paziente})
    }
  }

  aggiornaPaziente(paz) {

        const url_paziente = `${process.env.REACT_APP_API_URL}/dc/api_paziente/${paz.id}`
        fetch(url_paziente, {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        })
          .then(result => result.json())
          .then(result => {
            try {
              const paziente = result.results[0]
              this.setState({
                paziente: paziente,
                value: 'piano',
                openCDialog: true
              })

            } catch (e) {
              this.setState({
                logged_in: false
              })
            }
          })

  }


cercaPazienti = () => {
    const url_paz = `${process.env.REACT_APP_API_URL}/dc/ricerca_pazienti?q=` + this.state.ricerca_paz;
    try {
      clearTimeout(this.sp);
    } catch(e) {;}
    this.sp = setTimeout( () => {
      api.call_get(url_paz, (result) => {
        const pazienti = result.results
        this.setState({
          lista_pazienti: pazienti
        })
      });
    }, 350);
  }


  handleChange = (event, value) => {
    if (value === 'piano') {
      this.setState({ curr_piano: null })
    }
    if (value === 'fatture') {
      this.setState({ curr_cod_fattura: new Date().getTime() })
    }
    this.setState({ value });
  };

  handleClickPiano = () => {
    this.setState({ curr_piano: null,  })
  }

  setCurrPiano = (id, piano) => {
    this.setState({
      curr_piano: id,
      piano: piano
    })
  }

  caricaPiano = (id) => {
    let piano = undefined
    const url_piani = `${process.env.REACT_APP_API_URL}/dc/piani_terapeutici/${this.props.pid}`
      fetch(url_piani, {
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      })
        .then(result => result.json())
        .then(result => {
          const piani  = result.results
          for (let k = 0; k < piani.length; ++k) {
            if (piani[k].id = id) {
              piano = piani[k]
              break
            }
          }
          this.setState({
            curr_piano: id,
            piano: piano,
            value: 'piano'
          })
      }) 
  }

  clickPianoUtente(id) {
    this.caricaPiano(id)
  }



  render() {
    const { pid } = this.props
    const { value }  = this.state
    const { paziente }  = this.state
    const { curr_piano }  = this.state
    const { curr_cod_fattura }  = this.state
    const { piano }  = this.state

    return (
      <Container component="main" maxWidth="md">
          <Grid container xs={12}>
            <Grid item xs={12} >
                {paziente === undefined && 
                  <Grid container xs={12}>
                    <Grid item xs={6} >
                       <h2>Ricerca pazienti</h2>
                    </Grid>
                    <Grid item xs={6} >
                      <TextField
                    name={'lista_pazienti'}
                    label={'Cerca pazienti per cognome e nome o cellulare'}
                    style={{ width: "80%" }}
                    onChange={(evt) => this.setState({
                      ricerca_paz:  evt.target.value
                    })}
                    onKeyDown={(evt) => { if (evt.key === 'Enter') this.cercaPazienti(); }}
                    InputProps={{endAdornment:  <IconButton
                                aria-label="Cerca"
                                title="Cerca"
                                color="primary"
                                size="small"
                                onClick={this.cercaPazienti}
                                edge="end"><SearchIcon />
                              </IconButton>}}
                    />
                    </Grid>    
                  </Grid>
                }
                {paziente !== undefined &&
                <Grid container xs={12} style={{display: 'flex'}} justify="space-between">
                  <Grid item xs={4} >
                    <h2>
                      {paziente.cognome} {paziente.nome}
                    </h2>
                  </Grid>
                  <Grid item xs={2} >
                    <Typography variant={'body1'} component="div" style={{ "paddingTop": 25, flex: 1 }}>
                    Modulistica:&nbsp;&nbsp;
                    {!paziente.has_moduli && <CloseIcon style={{position: 'relative', 'top': 3, color: 'red'}}/>}
                    {paziente.has_moduli && <CheckIcon style={{position: 'relative', 'top': 3, color: 'green'}}/>}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} >
                    <Typography variant={'body1'} component="div" style={{ "paddingTop": 23, flex: 1 }}>
                      {paziente.has_moduli && <Button size="small" onClick={() => { this.toggleConsOpen() }} variant="contained" color="secondary">Moduli<DescriptionIcon/></Button>}
                      {!paziente.has_moduli && <Button size="small" onClick={() => { this.toggleConsOpen() }} variant="contained" color="primary">Moduli<DescriptionIcon/></Button>}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} >
                    <Typography variant={'body1'} component="div" style={{ "paddingTop": 15, flex: 1 }}>
                      <Link component={RouterLink} to={'/edit_paziente/' + pid}>
                      <Button size="small" variant="contained" color="secondary">Modifica paziente <EditIcon/></Button>
                      </Link>&nbsp;
                      <Button size="small" onClick={() => { this.props.setPaziente(undefined) }} variant="contained" color="secondary">Lista pazienti <ListIcon/></Button>
                      </Typography>
                  </Grid>
                </Grid>
                }
            </Grid>
            {paziente === undefined && 
            <Grid item xs={12} >
              <Paper elevation={5} style={{ margin: '0 15px 5px 0' }}>
                  <h3>Pazienti {this.props.sede_bloccata && <span style={{color: "#A60000"}}>con criticità</span>}</h3>
                  <Table dense={false}>
                    <TableHead>
                        <TableRow>
                          <TableCell>Paziente</TableCell>
                          <TableCell>Telefono/Cell</TableCell>
                          <TableCell>CF</TableCell>
                          <TableCell align="center">Modulistica</TableCell>
                          <TableCell align="center">Sede pref.</TableCell>
                          <TableCell>
                          <Link color="inherit" component={RouterLink} to="/nuovo_paziente/">
                          <Tooltip title="nuovo paziente">
                              <Fab size="small" color="secondary">
                                <Add/>
                              </Fab>
                            </Tooltip>  
                          </Link>
                          </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {this.state.lista_pazienti.map((item) => {
                      return (<TableRow>
                          <TableCell>{item.cognome} {item.nome}</TableCell>
                          <TableCell>{item.cellulare || item.telefono}</TableCell>
                          <TableCell>{item.nic || 'N.D'}</TableCell>
                          <TableCell align="center">
                            {!item.has_moduli && <CloseIcon style={{color: 'red'}}/>}
                            {item.has_moduli && <CheckIcon style={{color: 'green'}}/>}
                          </TableCell>
                          <TableCell align="center">{item.sede_nome_breve}</TableCell>
                          <TableCell>
                  
                            <Tooltip title="seleziona paziente">
                              <Fab size="small" color="secondary" onClick={() => { this.props.setPaziente(item) }}>
                                <CheckIcon/>
                              </Fab>
                            </Tooltip>  
                            
                          </TableCell>
                      </TableRow>)
                    })
                    }
                    </TableBody>
                  </Table>
              </Paper>
            </Grid>  
            } 
            {paziente !== undefined && 
            <Grid item xs={12} >
                <Paper elevation={5} style={{ margin: '0 15px 5px 0' }}>
                  <Tabs value={value} paziente_id={pid} onChange={this.handleChange}>
                    <Tab value='piano' label="Piani di cure" />
                    <Tab value='appuntamenti' label="Appuntamenti"/>
                    <Tab value='task' label="Task"/>
                    <Tab value='trattamenti' label="Trattamenti"/>
                    <Tab value='fatture' label="Fatture"/>
                    {/*<Tab value='accoglienza' label="Accoglienza"/>*/}
                  </Tabs>
                </Paper>
                <Paper elevation={5} style={{ margin: '0 15px 5px 0' }}>
                {value === 'piano' && <TabContainer paziente={paziente} paziente_id={pid}>
                  {curr_piano !== null && <PianoTerapeutico updatePianoObj={this.updatePianoObj} paziente={paziente} piano={curr_piano} piano_obj={ piano } /> }
                  {curr_piano === null && <PianiTerapeutici paziente_id={pid} setCurrPiano={this.setCurrPiano}  /> }
                  </TabContainer>
                }
                {value === 'appuntamenti' && <TabContainer paziente={paziente} paziente_id={pid}>
                  <AppuntamentiPaziente paziente={paziente} />
                  </TabContainer>
                }
                {value === 'task' && <TabContainer paziente={paziente} paziente_id={pid}>
                  <TaskPaziente paziente={paziente} />
                  </TabContainer>
                }
                {value === 'trattamenti' && <TabContainer  paziente={paziente} paziente_id={pid}>
                  <Trattamenti paziente_id={pid} paziente={paziente} clickPianoUtente={this.clickPianoUtente.bind(this)}/>
                  </TabContainer>
                }
                {value === 'fatture' && <TabContainer  paziente={paziente} paziente_id={pid}>
                  <Fatture paziente_id={pid} paziente={paziente} curr_cod_fattura={curr_cod_fattura} clickPianoUtente={this.clickPianoUtente.bind(this)}/>
                  </TabContainer>
                }
                {value === 'accoglienza' && <TabContainer  paziente={paziente} paziente_id={pid}>
                  <Accoglienza paziente_id={pid} paziente={paziente} clickPianoUtente={this.clickPianoUtente.bind(this)}/>
                  </TabContainer>
                }
                
                </Paper>
                  
            </Grid>}
            <Grid item xs={2} >
                
            </Grid>  
          </Grid>
        
      <DialogModuli paziente={paziente}  allsets={this.state.da_eseguire_associati} toggleOpen={() => {this.setState({altri: [], selectedFile: undefined}); this.toggleConsOpen()}} afterSave={() => {this.aggiornaPaziente(paziente); this.toggleConsOpen();}} open={this.state.openCDialog} />
      </Container>
    )
  }
}
export default Pazienti
