import React, { Component } from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import DialogTitle from '@material-ui/core/DialogTitle'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import VisibilityIcon from '@material-ui/icons/Visibility'
import DeleteIcon from '@material-ui/icons/Delete'
import ExcelIcon from '@material-ui/icons/FileCopy'
import RefreshIcon from '@material-ui/icons/Refresh'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import EuroIcon from '@material-ui/icons/EuroSymbol';
import Tooltip from "@material-ui/core/Tooltip"
import Button from '@material-ui/core/Button'
import Link from '@material-ui/core/Link'
import NumberFormat from 'react-number-format'
import MomentUtils from '@date-io/moment'
import RiepilogoEseguiti from '../components/RiepilogoEseguiti'
import api from '../api_calls/api'


const FormDialog = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }


  const handleCreate = (btn) => {
    const vl =   document.getElementById("nome_piano").value
    if(vl !== '') {
      const data = {
        'paziente_id': props.paziente_id,
        'nome_piano': vl
      }
      const url =  `${process.env.REACT_APP_API_URL}/dc/aggiungi_piano/${props.paziente_id}`
      fetch(url, {
        method: "POST",
        body: data,
        Authorization: `JWT ${localStorage.getItem('token')}`
      }).then(result => result)
      .then(result => {
          console.log(result)
          props.callback()
      })
    }
  }

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleClickOpen}>
        Nuovo piano
      </Button>&nbsp;<Tooltip title="Scarica excel"><Button onClick={() => { window.location.href = "/dc/xl_pdc/?type=openpdc&id=" + this.props.paziente_id; }} variant="contained" color="primary">excel piani attivi&nbsp;<ExcelIcon/></Button></Tooltip>
      &nbsp;<Tooltip title="Scarica excel"><Button onClick={() => { window.location.href = "/dc/xl_pdc/?type=allpdc&id=" + this.props.paziente_id; }} variant="contained" color="primary">excel completo&nbsp;<ExcelIcon/></Button></Tooltip>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Nuovo piano di cure</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Inserisci il nome del nuovo piano di cure.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="nome_piano"
            label="Nome piano"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Chiudi
          </Button>
          <Button onClick={handleCreate} color="primary">
            Crea
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}



class PianiTerapeutici extends Component {
    
    state = {
        piani: [],
        open: true
    }

    caricaPiani = () => {
      const url_piani = `${process.env.REACT_APP_API_URL}/dc/piani_terapeutici/${this.props.paziente_id}`
        fetch(url_piani, {
          headers: {
            Authorization: `JWT ${localStorage.getItem('token')}`
          }
        })
          .then(result => result.json())
          .then(result => {
            const piani  = result.results
            this.setState({
              piani: piani
            })
        }) 
    }

    componentDidMount() {
      this.caricaPiani()
    }
    componentDidUpdate(propsPrecedenti) {
      if (this.props.paziente_id !== propsPrecedenti.paziente_id) {
        this.caricaPiani()
      }
      
    }

    clickPiano = (id, piano) => {
        this.props.setCurrPiano(id, piano)
    }

    handleClickNewPT = () => { 
      
    }

    creaNuovoPiano = () => {
      const url =  `${process.env.REACT_APP_API_URL}/dc/aggiungi_piano/${this.props.paziente_id}`
      fetch(url, {
        method: "POST", 
        headers: {
          Authorization: `JWT ${localStorage.getItem('token')}`
        }
      }).then(result => result)
      .then(result => {
          console.log(result)
          this.caricaPiani()
      })
    }


    rimuoviPiano = (id) => {
      const url =  `${process.env.REACT_APP_API_URL}/dc/rimuovi_piano/${this.props.paziente_id}/${id}/`
      api.call_get(url, result => {
          console.log(result)
          this.caricaPiani()
      });
    }

    render() {
    const moment = new MomentUtils()  
    return(
        <div>
            Lista piani di cure <Button color="secondary" variant="contained" onClick={this.caricaPiani}><RefreshIcon /></Button>&nbsp;&nbsp;<Button variant="contained" color="primary" onClick={this.creaNuovoPiano}>
                                        Nuovo piano
                                    </Button>&nbsp;<Tooltip title="Scarica excel"><Button onClick={() => { window.location.href = "/dc/xl_pdc/?type=openpdc&id=" + this.props.paziente_id; }} variant="contained" color="primary">excel piani attivi&nbsp;<ExcelIcon/></Button></Tooltip>
      &nbsp;<Tooltip title="Scarica excel"><Button onClick={() => { window.location.href = "/dc/xl_pdc/?type=allpdc&id=" + this.props.paziente_id; }} variant="contained" color="primary">excel completo&nbsp;<ExcelIcon/></Button></Tooltip>

            <Table dense={false}>
                <TableHead>
                    <TableRow>
                      <TableCell align="left">DATA</TableCell>
                      <TableCell align="left">PIANO</TableCell>
                      <TableCell align="left">ESEGUIBILE</TableCell>
                      <TableCell align="left">SALDI</TableCell>
                      <TableCell align="left">&nbsp;</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {this.state.piani.map((item) => {
                  if (item.nome === "") item.nome = 'piano'
                  return (<TableRow>
                    <TableCell css={{"vertical-align": 'top'}}>
                        {moment.moment(item.data).format('DD/MM/YY')}
                    </TableCell>
                    <TableCell>
                        <Link style={{cursor: "pointer"}} onClick={() => { this.clickPiano(item.id, item) }}>{item.nome} #{item.id}</Link> {item.finanziato && <Tooltip title="Piano Finanziato" arrow><EuroIcon style={{color: "#008500"}} /></Tooltip>}<br/>
                        {item.stato} {(item.stato === "Accettato" || item.stato === "Non accettato") && !item.data_firma_paziente && <span style={{color: "#A60000"}}><b>DA FIRMARE</b></span>}
                        {(item.stato === "Accettato" || item.stato === "Non accettato") && item.data_firma_paziente && <span style={{color: "#008500"}}><b>FIRMATO IL {moment.moment(item.data_firma_paziente).format('DD/MM/YY')}</b></span>}
                        {(item.stato === "Da autorizzare") && <span style={{color: "#A60000"}}><b>Chiamare Magdalo o Riccardo</b></span>}
                        {(item.stato === "Non autorizzato") && <span style={{color: "#A60000"}}><b>Preventivo non autorizzato</b></span>}
                        {item.eseguibile && !item.fatturabile_in_acconto && item.ha_trattamenti_da_fatturare &&
                        <><br/><Tooltip title="Eseguire i trattamenti senza emettere acconti, e poi procedere direttamente all'emissione della fattura di saldo."><span style={{color: "#555555"}}><b>FATTURABILE SOLO A SALDO</b></span></Tooltip></>}
                    </TableCell>
                    <TableCell align="center">
                      {item.eseguibile && <CheckCircleIcon style={{color: "#008500"}} />}
                      {item.stato === "Accettato" && !item.eseguibile &&  <Tooltip title={item.analisi_eseguibilita} arrow><HighlightOffIcon style={{color: "#A60000"}} /></Tooltip>}
                    </TableCell>
                    <TableCell align="right">
                    <NumberFormat value={item.prezzo_scontato_totale} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                     {item.saldi_piano &&<table className="riepilogoTable">
                       <tr>
                         <td align="left">Pianificato</td>
                         <td align="center">=</td>
                         <td align="right" >
                          <NumberFormat value={Number(item.saldi_piano.pianificato)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} />
                         </td>
                       </tr>
                       <tr>
                         <td align="left">Eseguito</td>
                         <td align="center" >=</td>
                         <td align="right" ><NumberFormat value={Number(item.saldi_piano.eseguito)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></td>
                       </tr>
                       {/*<tr> D.P. commentato perché gli anticipi su più piani non consentono di ripartire gli importi, se non lo rivogliono lo togliamo
                         <td align="left">Fatturato</td>
                         <td align="center" >=</td>
                         <td align="right" ><NumberFormat value={Number(item.saldi_piano.fatturato)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></td>
                       </tr>
                       <tr>
                         <td align="left">Da fatturare</td>
                         <td align="center" >=</td>
                         <td align="right" ><NumberFormat value={Number(item.saldi_piano.da_fatturare)} displayType={'text'} thousandSeparator={'.'} decimalSeparator={','} fixedDecimalScale={2} decimalScale={2} suffix={' EUR'} /></td>
                       </tr>*/}
                  </table>}
                    </TableCell>
                    <TableCell>
                        <Tooltip title="Visualizza"><Button onClick={() => { this.clickPiano(item.id, item) }} variant="contained" color="primary"><VisibilityIcon/></Button></Tooltip>
                        {item.stato !== "Da autorizzare" && item.stato !== "Non autorizzato" && item.stato === "In preparazione" && <Tooltip title="Rimuovi"><Button onClick={() => { this.rimuoviPiano(item.id) }} variant="contained" ><DeleteIcon/></Button></Tooltip>}
                        {item.stato !== "Da autorizzare" && item.stato !== "Non autorizzato" && item.stato !== "In preparazione" && <>&nbsp;<Tooltip title="Scarica excel"><Button onClick={() => { window.location.href = "/dc/xl_pdc/?id=" + item.id; }} variant="contained" color="primary"><ExcelIcon/></Button></Tooltip></>}
                    </TableCell>
                  </TableRow>)
                  }
                )
                }
                </TableBody>
              </Table>
              <RiepilogoEseguiti paziente_id={this.props.paziente_id} />
        </div>
    )
    }
      

}
export default PianiTerapeutici;
